import * as React from "react"

import styled from "styled-components"
import { graphql, Link } from "gatsby"

import { useI18n } from '../../locale.js'
import { useTranslation } from "react-i18next"

const NotFoundPage = () => {
  const {t} = useTranslation()
  return(
  <ContainerStyled>
    <h2>
      {t('oops')}
      <br />
      {t('notFound')}
    </h2>
    <LinkStyled to="/">
      {t('backToMain')}
    </LinkStyled>
  </ContainerStyled>
  )
}

export default NotFoundPage

const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-height: 100vh;

  background-color: #030303;
  text-align: center;
  color: #fff;

  h2 {
    margin: 40px 0 80px;
  }
`

const LinkStyled = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 18rem;
  height: 3.5rem;
  min-height: 3.5rem;
  padding: 0;

  background: #6ce24f;
  border: none;

  color: #fff;
  font-size: 1rem;
  font-weight: 600;

  &:hover {
    background: linear-gradient(135deg, #030303, #6ce24f);
  }
`
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;